import React from "react";
import { Link } from "gatsby";

import "./style.scss";

class PostFooter extends React.Component {
  constructor() {
    super();
    this.displayName = "PostFooter";
  }

  render() {
    return (
      <div className="post-footer">
        <div className="return-link">
          <p>
            <strong>Andrew Mahon</strong> is a seasoned engineering leader based
            in San Francisco.{" "}
            <a href="mailto:andrewmahon@fastmail.com" className="external">
              Get in touch!
            </a>
          </p>
          <small>
            <Link to="/blog">← index</Link>
          </small>
        </div>
      </div>
    );
  }
}

export default PostFooter;
