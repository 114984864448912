import React from "react";
import Helmet from "react-helmet";
import PostFooter from "../../components/postFooter/init";
import { graphql } from 'gatsby';

import $ from "jquery";
import "datatables";

import Layout from "../../components/layout";

import "./post.scss";

class Template extends React.Component {
  componentDidMount() {
    $("table").DataTable({
      scrollX: "100%",
      paging: false,
      searching: false,
      info: false,
      autoSize: false,
      dom: "t",
    });
  }

  render() {
    const { markdownRemark } = this.props.data;
    const { frontmatter, html } = markdownRemark;

    return (
      <Layout>
        <div className="blog-post-container content">
          <Helmet title={frontmatter.title + " - Andrew Mahon"} />

          <h2>{frontmatter.title}</h2>
          <div className="details">
            <p>{frontmatter.date}</p>
          </div>

          <div className="blog-post">
            <div
              className="blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>

          <hr />

          <PostFooter />
        </div>
      </Layout>
    );
  }
}

export default Template;

export const pageQuery = graphql`
  query PostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        published
        date(formatString: "DD MMMM, YYYY")
      }
    }
  }
`;
